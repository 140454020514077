/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ILocation, ISubpageHero, IQuote, IFeatureText } from '@standardTypes';
import { StaticImage } from 'gatsby-plugin-image';

import React from 'react';

import { Layout } from '@components/layout';
import { Feature, Quote, SubpageHero } from '@components/molecules';
import { TextWrapperSection } from '@components/organisms/standard';

interface AboutPageProps {
  pageContext: {
    page: any;
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

interface IAboutPageData {
  quote: IQuote;
  textSection: IFeatureText;
  textSectionTwo: IFeatureText;
}

const AboutPage: React.FC<AboutPageProps> = ({ pageContext }) => {
  const { page, translationUrls } = pageContext;
  const { quote, textSection, textSectionTwo }: IAboutPageData =
    page.ACF_AboutPageContent;
  const pageHero: ISubpageHero = page.ACF_SubpageHero;
  const Background = '../../assets/images/sunset-min.png';

  return (
    <Layout {...page} translationUrls={translationUrls}>
      <SubpageHero
        title={pageHero.title}
        description={pageHero.description}
        image={pageHero.image}
        additionalOptions={false}
      />
      {textSection && (
        <TextWrapperSection>
          <StaticImage src={Background} alt="background" />
          <div className="text-section">
            <Feature
              image={textSection.textSectionImage}
              imageOnLeft
              title={textSection.textSectionTitle}
              subtitle={textSection.textSectionSubtitle}
              description={textSection.textSectionText}
            />
          </div>
        </TextWrapperSection>
      )}

      {quote && <Quote text={quote.quoteText} author={quote.quoteAuthor} />}

      {textSectionTwo && (
        <TextWrapperSection>
          <StaticImage src={Background} alt="background" />
          <div className="text-section">
            <Feature
              image={textSectionTwo.textSectionImage}
              imageOnLeft
              title={textSectionTwo.textSectionTitle}
              subtitle={textSectionTwo.textSectionSubtitle}
              description={textSectionTwo.textSectionText}
            />
          </div>
        </TextWrapperSection>
      )}
    </Layout>
  );
};

export default AboutPage;
